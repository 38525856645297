import React from 'react'

const Contact = () => {
  return (
    <div className='w-full flex justify-center items-center flex-col'>
      <h1 className='text-center mb-4 text-4xl font-extrabold md:text-3xl tracking-wide lg:text-5xl text-white'>Wir gestalten Ihren Erfolg.</h1>
      <div className='w-56 flex justify-center items-center flex-col'>
        <a
          href='mailto:varius.leo.design@gmail.com'
          className='mt-8 w-full inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white bg-primary rounded-lg hover:bg-primary focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900'
        >
          Schreib uns doch einfach
        </a>
      </div>
    </div>
  )
}

export default Contact
