import * as React from 'react'

const SvgComponent = (props) => (
  <svg
    id='Ebene_1'
    xmlns='http://www.w3.org/2000/svg'
    x={0}
    y={0}
    viewBox='0 0 283.5 283.5'
    style={{
      enableBackground: 'new 0 0 283.5 283.5'
    }}
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {'.st0{fill:none;stroke:#87bd26;stroke-width:3.94;stroke-miterlimit:10}'}
    </style>
    <path
      className='st0'
      d='m219.9 124.7-116 116c-1.6 1.6-4.2 1.4-6-.4L42.6 185c-1.7-1.7-1.9-4.4-.3-6l116-116c.8-.8 1.9-1.1 3-1 1.1.1 2.1.6 2.9 1.4l55.3 55.3c.9.8 1.3 1.9 1.4 2.9.1 1.2-.2 2.3-1 3.1zM40.3 183.2l60.4 60.4c.3.3.3.9 0 1.2l-6.2 6.2c-.3.3-.9.3-1.2 0L33 190.6c-.3-.3-.3-.9 0-1.2l6.2-6.2c.3-.3.8-.3 1.1 0z'
    />
    <path
      className='st0'
      d='M220.8 121.8c0-1.1-.5-2.2-1.3-3l-55.3-55.3c-.9-.8-1.9-1.3-3-1.3 16.7-9.5 38.3-7.2 52.6 7.1 14.2 14.2 16.6 35.8 7 52.5z'
    />
    <path
      transform='rotate(-45.001 231.634 51.36)'
      className='st0'
      d='M217.2 39.2H246v24.3h-28.8z'
    />
    <path
      transform='rotate(-45.001 217.712 65.27)'
      className='st0'
      d='M212.8 61.7h9.9v7.2h-9.9z'
    />
    <circle
      cx={231.9}
      cy={50.8}
      r={4.5}
      style={{
        fill: '#87bd26'
      }}
    />
  </svg>
)

export default SvgComponent
