import React from 'react'
import VariusLogo from '../assets/logo_design.png'

const Header = ({ toggleMenu, active = false, withMenu = true }) => {
  return (
    <header className='relative bg-background top-0 left-0 right-0 z-50'>
      <nav className='px-2 sm:px-4 rounded'>
        <div className='flex flex-wrap justify-between items-center mx-auto'>
          <a href='/' className='flex items-center'>
            <div className='w-32 md:w-40'><img className='w-full h-full' src={VariusLogo} /></div>
          </a>
          {withMenu && (
            <button onClick={() => toggleMenu()} data-collapse-toggle='navbar-default' type='button' className='inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600' aria-controls='navbar-default' aria-expanded='false'>
              <span className='sr-only'>Open main menu</span>
              {active
                ? (<svg strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'><path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' /></svg>)
                : (<svg className='w-6 h-6' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z' clipRule='evenodd' /></svg>)}
            </button>
          )}
          <div className='hidden w-full md:block md:w-auto' id='navbar-default'>
            <ul className='flex flex-col p-4 mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium '>
              <li>
                <a href='#home' className='text-white block py-2 pr-4 pl-3 bg-primary rounded md:bg-transparent md:text-primary md:p-0' aria-current='page'>Home</a>
              </li>
              <li>
                <a href='#team' className='text-white block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover-text-primary md:p-0 '>Team</a>
              </li>
              <li>
                <a href='#services' className='text-white block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover-text-primary md:p-0 '>Leistungen</a>
              </li>
              <li>
                <a href='#references' className='text-white block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover-text-primary md:p-0 '>Referenzen</a>
              </li>
              <li>
                <a href='#contact' className='text-white block py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover-text-primary md:p-0 '>Kontakt</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
