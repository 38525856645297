import React from 'react'
import Frieder from '../pics/about_frieder.png'
import Leo from '../pics/about_leo.png'
import Aid from '../pics/about_aid.png'
import GreenBackground from '../pics/green_background.png'

const TEAM = [
  {
    name: 'Leo',
    fullName: 'Leonard Bielig',
    role: 'Kommunikationsdesign',
    position: 'Geschäftsführer',
    image: Leo,
    description: 'Leo ist ein Designer und Kommunikationsfachmann. Er hat bereits mehrere Webseiten und Anwendungen entworfen und ist ein Experte in der Entwicklung von Webseiten mit React und Node.js. Er hat auch Erfahrung in der Entwicklung von mobilen Anwendungen mit React Native und in der Entwicklung von Desktopanwendungen mit Electron.'
  },
  {
    name: 'Frieder',
    fullName: 'Frieder Vibrans',
    role: 'Software-Entwickler',
    image: Frieder,
    description: 'Frieder ist ein Software-Entwickler, der sich auf die Entwicklung von Webanwendungen spezialisiert hat. Er hat bereits mehrere Webanwendungen entwickelt und ist ein Experte in der Entwicklung von Webanwendungen mit React und Node.js. Er hat auch Erfahrung in der Entwicklung von mobilen Anwendungen mit React Native und in der Entwicklung von Desktopanwendungen mit Electron.'
  },
  {
    name: 'Aid',
    fullName: 'Aid Vehabovic',
    role: 'Fotograf',
    image: Aid,
    description: 'Aid ist ein Fotograf. Er hat bereits mehrere Webseiten und Anwendungen entworfen und ist ein Experte in der Entwicklung von Webseiten mit React und Node.js. Er hat auch Erfahrung in der Entwicklung von mobilen Anwendungen mit React Native und in der Entwicklung von Desktopanwendungen mit Electron.'
  }
]

const AboutUs = () => {
  return (
    <div>
      <div className='flex justify-center flex-wrap'>
        <p className='mt-24 mb-12 text-md md:text-xl px-8 md:px-40 xl:px-52 text-white text-center leading-relaxed text-md font-light'>Wir sind eine junge und ambitionierte Designagentur, welche für Ihre Kunden einzigartige Designlösungen entwickelt. Dabei arbeiten wir stets an der Weiterentwicklung unserer Arbeit, um unseren Kunden immer die beste Qualität zu bieten. Um auch für Sie ausdrucksstarke und außergewöhnliche Kommunikationslösungen zu realisieren, steht Ihnen ein löwenstarkes Team zur Seite. Für jedes Projekt erarbeiten wir individuelle Lösungen. Dabei stehen Sie als Kunde immer im Mittelpunkt unserer Arbeit.</p>
        {TEAM.map((member, index) => {
          return (
            <div key={index} className='person'>
              <div className='about_container'>
                <div className='about_container-inner'>
                  <img
                    className='circle'
                    src={GreenBackground}
                    alt='person'
                  />
                  <img
                    className='img img1'
                    src={member.image}
                    alt='person'
                  />
                </div>
              </div>
              <div className='divider' />
              <div className='name'>{member.name}</div>
              <div className='title'>{member.fullName}</div>
              <div className='title'>{member.role}</div>
              {member.position && <div className='title'>{member.position}</div>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AboutUs
