import React from 'react'
import { motion } from 'framer-motion'

const Menu = ({ toggleMenu, active = false }) => {
  const variantsUl = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  }
  const variantsLi = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  }

  if (!active) return <div />
  return (
    <div className='absolute top-0 left-0 right-0 w-full h-screen bg-background text-white flex justify-center items-center'>
      <motion.ul variants={variantsUl}>
        <motion.li
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }} variants={variantsLi} className='my-8 font-bold text-xl'
        ><a onClick={() => toggleMenu()} href='#'>Home</a>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }} variants={variantsLi} className='my-8 font-bold text-xl'
        ><a onClick={() => toggleMenu()} href='#team'>Team</a>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }} variants={variantsLi} className='my-8 font-bold text-xl'
        ><a onClick={() => toggleMenu()} href='#services'>Leistungen</a>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }} variants={variantsLi} className='my-8 font-bold text-xl'
        ><a onClick={() => toggleMenu()} href='#references'>Referenzen</a>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }} variants={variantsLi} className='my-8 font-bold text-xl'
        ><a onClick={() => toggleMenu()} href='#contact'>Kontakt</a>
        </motion.li>
      </motion.ul>
    </div>
  )
}

export default Menu
