import * as React from 'react'

const SvgComponent = (props) => (
  <svg
    id='Ebene_1'
    xmlns='http://www.w3.org/2000/svg'
    x={0}
    y={0}
    viewBox='0 0 283.5 283.5'
    style={{
      enableBackground: 'new 0 0 283.5 283.5'
    }}
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {'.st0{fill:none;stroke:#87bd26;stroke-width:3.94;stroke-miterlimit:10}'}
    </style>
    <path
      className='st0'
      d='M38.6 54.8h206.3c2.3 0 4.1 1.8 4.1 4.1v149.3c0 2.3-1.8 4.1-4.1 4.1H38.6c-2.3 0-4.1-1.8-4.1-4.1V58.9c0-2.3 1.8-4.1 4.1-4.1zM34.5 179.4h214.4M141.7 211.3v17.4M103.8 228.7h75.9'
    />
    <circle
      cx={141.7}
      cy={194.2}
      r={5.3}
      style={{
        fill: '#87bd26'
      }}
    />
  </svg>
)

export default SvgComponent
