import './App.css'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AboutUs from './components/AboutUs'
import Footer from './components/Footer'
import Services from './components/Services'
import Contact from './components/Contact'
import References from './components/References'
import Header from './components/Header'
import Menu from './components/Menu'
import { motion, AnimatePresence } from 'framer-motion'
import Imprint from './components/Imprint'
import Privacy from './components/Privacy'
import Schlosskirche from './assets/Hero_Schlosskirche_PNG.png'
import Clebot from './assets/Hero_CorporateDesign_PNG.png'
import DPHNS from './assets/Hero_Website_PNG.png'

function App () {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/imprint' element={<Imprint />} />
        <Route path='/privacy' element={<Privacy />} />
      </Routes>
    </Router>
  )
}

const HEROSLIDES = [
  {
    title: 'Ihr Unternehmen bekommt bei uns ein Gesicht.',
    subtext: 'Entwicklung des Corporate Designs für das Unternehmen Clebot.',
    image: Clebot
  },
  {
    title: 'Hinterlassen Sie einen bleibenden Eindruck bei Ihren Kunden.',
    subtext: 'Entwicklung des Webauftritts für das Unternehmen DPHNS.',
    image: DPHNS
  },
  {
    title: 'Ihre kreativen Wünsche werden bei uns Realität.',
    subtext: 'Etikettendesign und Produktfotografie für die Schlosskirche Wittenberg.',
    image: Schlosskirche
  }
]

const Hero = () => {
  const [current, setCurrent] = useState(0)
  const length = HEROSLIDES.length

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(current === length - 1 ? 0 : current + 1)
    }, 8000)
    return () => clearInterval(interval)
  }, [current])

  const { title, subtext, image } = HEROSLIDES[current]

  return (
    <div className='absolute top-0 left-0 right-0 bottom-0 m-auto flex flex-col md:flex-row justify-center items-center'>
      <AnimatePresence>
        <motion.div
          key={title}
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ type: 'spring', duration: 2, bounce: 0.25 }}
          exit={{ opacity: 0, x: -200, transition: { duration: 0.5, type: 'spring' } }}
          className='text-center md:text-left px-8 mb-12 max-w-sm absolute top-[16%] md:left-[10%]'
        >
          <h1 className='font-bold text-2xl md:text-3xl xl:text-4xl'>{title}</h1>
          <div className='w-full my-2 h-2 bg-[#7BAD27]' />
          <p>{subtext}</p>
        </motion.div>
      </AnimatePresence>
      <AnimatePresence>
        <motion.img
          className='h-[37%] absolute bottom-[12%] lg:h-[55%] lg:bottom-[0%] xl:h-[65%] md:bottom-[0%] md:right-[10%]'
          key={image}
          src={image}
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ type: 'spring', duration: 2, bounce: 0.5, delay: 0.5 }}
          exit={{ opacity: 0, x: 200, transition: { duration: 1, type: 'spring' } }}
        />
      </AnimatePresence>
    </div>
  )
}

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <div className='flex flex-col justify-center w-full min-h-screen bg-[#101827]'>
      <main className='w-full'>
        <section id='home' className='bg-gradient-to-b from-white via-white to-bg-background bg-white min-h-screen w-full flex-col justify-center relative'>
          <Header active={menuOpen} toggleMenu={toggleMenu} />
          <Hero />
        </section>
        <section className='relative min-h-screen w-full flex-col justify-center'>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: { type: 'spring', bounce: 0.4, duration: 0.8 } }}
            viewport={{ once: true }}
            className='absolute top-0 left-0 right-0 bottom-0 m-auto flex flex-col md:flex-row justify-center items-center'
          >
            <div className='relative top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
              <div className='max-w-6xl flex flex-col justify-center items-center'>
                <h1 className='px-8 text-center font-extrabold tracking-tight leading-none text-white text-3xl lg:text-4xl'>Kunst ist Ausdruck.</h1>
                <h1 className='px-8 text-center md:mb-4 font-extrabold tracking-tight leading-none text-white text-3xl lg:text-4xl'>Design ist Kommunikation.</h1>
                <p className='py-8 px-8 text-md md:text-xl font-light md:px-40 xl:px-52 text-white text-center leading-relaxed'>Wir bieten Ihnen sowohl individuelle, moderne und zielgruppengerechte Kommunikationslösungen an, als auch kreative und ausdrucksstarke künstlerische Werke. Ganz nach Ihren Wünschen, verbunden mit unserer Expertise, entwickeln wir Ihren Firmenauftritt – von der Website, zum Logo bis hin zur Leinwand oder Hausfassade. Wir helfen Ihnen gern bei all Ihren Designproblemen!</p>
                <a href='mailto:varius.leo.design@gmail.com' className='mt-2 md:mt-8 inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white bg-primary rounded-lg hover:bg-primary focus:ring-4 focus:ring-white-300 dark:focus:ring-blue-900'>
                  Schreib uns doch einfach
                </a>
              </div>
            </div>
          </motion.div>
        </section>
        <section id='team' className='ml-auto mr-auto max-w-6xl min-h-screen flex-col justify-center items-center'>
          <div className='px-12 w-full md:px-60 flex-col justify-center items-center'>
            <div className='max-w-sm border-b-8 border-b-primary m-auto pb-4'>
              <h1 className='text-white font-semibold text-3xl md:text-4xl text-center'>ein löwenstarkes</h1>
              <h1 className='text-white font-semibold text-3xl md:text-4xl text-center'>Team</h1>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 300 }}
            whileInView={{ opacity: 1, y: 0, transition: { type: 'spring', bounce: 0.4, duration: 0.8 } }}
            viewport={{ once: true }}
          >
            <AboutUs />
          </motion.div>
        </section>
        <section id='services' className='mt-24 mb-12 ml-auto mr-auto max-w-6xl min-h-screen flex-col justify-center items-center'>

          <div className='w-full flex justify-center'>
            <h1 className='px-4 py-8 border-b-8 border-b-primary text-white font-semibold text-3xl md:text-4xl text-center'>Leistungen</h1>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 300 }}
            whileInView={{ opacity: 1, y: 0, transition: { type: 'spring', bounce: 0.4, duration: 0.8 } }}
            viewport={{ once: true }}
          >
            <Services />
          </motion.div>

        </section>
        <section id='references' className='ml-auto mr-auto max-w-6xl mt-40 min-h-screen flex-col items-center'>
          <div className='w-full flex justify-center'>
            <h1 className='px-4 py-8 border-b-8 border-b-primary text-white font-semibold text-3xl md:text-4xl text-center'>Referenzen</h1>
          </div>
          <div>
            <motion.div
              initial={{ opacity: 0, y: 300 }}
              whileInView={{ opacity: 1, y: 0, transition: { type: 'spring', bounce: 0.4, duration: 0.8 } }}
              viewport={{ once: true }}
            >
              <References />
            </motion.div>
          </div>
        </section>
        <section id='contact' className='mt-40 mb-40 md:mt-40 md:mb-96 px-8'>
          <motion.div
            initial={{ opacity: 0, y: 300 }}
            whileInView={{ opacity: 1, y: 0, transition: { type: 'spring', bounce: 0.4, duration: 0.8 } }}
            viewport={{ once: true }}
          >
            <Contact />
          </motion.div>
        </section>
        <Footer />
        <Menu toggleMenu={toggleMenu} active={menuOpen} />
      </main>
    </div>
  )
}

export default App
