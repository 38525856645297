import React from 'react'

const Aktenkoffer = (props) => {
  return (
    <svg
      id='Ebene_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 283.5 283.5'
      style={{ enableBackground: 'new 0 0 283.5 283.5' }}
      xmlSpace='preserve'
      {...props}
    >
      <style>
        {'.st0{fill:none;stroke:#87bd26;stroke-width:3.94;stroke-miterlimit:10}'}
      </style>
      <path
        className='st0'
        d='M248.9 94v130.9c0 4.4-3.6 8-8 8H42.5c-4.4 0-8-3.6-8-8V94c20.6 32.1 55.2 54.3 95.2 58v.9c0 10.5 5.4 19.1 12.1 19.1s12.1-8.6 12.1-19.1v-.9c39.8-3.7 74.4-25.9 95-58z'
      />
      <path
        className='st0'
        d='M248.9 83.5V94c-20.6 32.1-55.2 54.3-95.2 58-.3-10.1-5.6-18.2-12.1-18.2s-11.7 8.1-12 18.2c-40-3.7-74.6-26-95.2-58V83.5c0-4.4 3.6-8.1 8-8.1h198.4c4.5 0 8.1 3.6 8.1 8.1z'
      />
      <path
        className='st0'
        d='M153.8 152.9c0 10.5-5.4 19.1-12.1 19.1s-12.1-8.6-12.1-19.1v-.9c.3-10.1 5.6-18.2 12-18.2s11.8 8.1 12.1 18.2c.1.3.1.6.1.9zM183.5 50.6v24.8h-10.3v-18h-63.8v18h-11V50.6z'
      />
    </svg>
  )
}

export default Aktenkoffer
