import React from 'react'
import Schallplatte from '../assets/jobs/Schallplatte.jpg'
import ClebotLogo from '../assets/jobs/Clebot_Logo.jpg'
import Eurocap from '../assets/jobs/Eurocap.jpg'
import DPHNS from '../assets/jobs/dphns.jpg'
import HealthyShit from '../assets/jobs/Healthy-Shit.jpg'
import Red from '../assets/jobs/red.jpg'

const REFERENCES = [
  {
    title: 'Schalplatte',
    img: Schallplatte,
    description: 'Coverdesign für Felix Reichelt, Volume Berlin Records'
  },
  {
    title: 'Clebot-Logo',
    img: ClebotLogo,
    description: 'Logogestaltung für Clebot ("maritime Müllabfuhr") als Anwendung auf einer Visitenkarte'
  },
  {
    title: 'DPHNS',
    img: DPHNS,
    description: 'Responsive Website für DPHNS'
  },
  {
    title: 'Healthy Shit',
    img: HealthyShit,
    description: 'Logogestaltung für das StartUp Healthy Shit als Anwendung auf einer Visitenkarte'
  },
  {
    title: 'red',
    img: Red,
    description: 'Plakatgestaltung für eine Veranstaltung von Volume Berlin Records und Richtig Dick Techno'
  },
  {
    title: 'Eurocap',
    img: Eurocap,
    description: 'Präsentation für den Motosurf Continental Cup Europe 2022'
  }
]

const References = () => {
  return (
    <div className='flex justify-center flex-wrap'>
      <p className='mt-24 mb-32 text-lg md:text-xl px-8 md:px-40 xl:px-52 text-white text-center leading-relaxed text-md font-light'>Unsere modernen und kreativen Gestaltungen sorgen für Aufsehen bei Ihrer Zielgruppe.
        Wir schaffen auch für Ihr Unternehmen einen einmaligen Wiedererkennungswert.
        Bis dahin lassen Sie sich von unseren vielfältigen Projekten inspirieren.
      </p>
      {REFERENCES.map((item, index) => {
        return (
          <div key={index}>
            <div className='relative group m-4 object-contain text-white flex flex-wrap items-center flex-col mb-8'>
              <div className='w-72 h-72 md:w-96 md:h-96 bg-cover bg-center' style={{ backgroundImage: `url(${item.img})` }} />
              <div className='absolute w-full p-6 text-sm bottom-0 text-white bg-background bg-opacity-75 opacity-0 group-hover:opacity-100'>
                {item.description}
              </div>
            </div>

          </div>
        )
      })}
    </div>
  )
}

export default References
