import * as React from 'react'

const SvgComponent = (props) => (
  <svg
    id='Ebene_1'
    xmlns='http://www.w3.org/2000/svg'
    x={0}
    y={0}
    viewBox='0 0 283.4 283.4'
    style={{
      enableBackground: 'new 0 0 283.4 283.4'
    }}
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {'.st0{fill:none;stroke:#87bd26;stroke-width:3.94;stroke-miterlimit:10}'}
    </style>
    <path className='st0' d='M100.5 220.5 225.7 95.3l-37.6-37.6L62.9 182.9' />
    <path
      className='st0'
      d='m100.5 220.5.4-25.2-18.8 6.1 5.9-18.5H62.9l-27.6 65.2zM188.1 57.7l7.3-7.2L233 88.1l-7.3 7.2'
    />
    <path
      className='st0'
      d='M195.4 50.5 208.8 37c2.4-2.3 6.2-2.3 8.5 0l29 29c2.4 2.3 2.4 6.2 0 8.5L232.9 88M88 182.9 200.5 70.4M100.9 195.3 212.7 83.5M48.1 218.4c9 2.3 14.6 8 17 17'
    />
  </svg>
)

export default SvgComponent
