import React from 'react'
import Aktenkoffer from '../assets/Aktenkoffer'
import Stift from '../assets/Stift'
import Kamera from '../assets/Kamera'
import Bildschirm from '../assets/Bildschirm'
import Spraypaint from '../assets/Spraypaint'

const SERVICES = [
  {
    title: 'Branding',
    logo: <Aktenkoffer />,
    description: 'Wir entwickeln ihre Markenidentität - vom Logo zur Visitenkarte bis hin zu weiteren Geschäftsausstattungen.'
  },
  {
    title: 'Grafikdesign',
    logo: <Stift />,
    description: 'Grafische Gestaltungen von Flyer, Plakaten, Präsentationen, CD-Cover und Booklets, Icons und Verpackungsdesigns.'
  },
  {
    title: 'Fotografie',
    logo: <Kamera />,
    description: 'Sie benötigen individuelle Fotos für ihre Projekte? Durch die Zusammenarbeit mit unserem erfahrenen Fotografen ist das kein Problem.'
  },
  {
    title: 'Webdesign',
    logo: <Bildschirm />,
    description: 'Wir entwickeln für Sie eine individuelle, moderne und ansprechende responsive Website, die Ihren Kunden einen überzeugenden Eindruck Ihres Unternehmens vermittelt.'
  },
  {
    title: 'Künsterliche Leistungen',
    logo: <Spraypaint />,
    description: 'Zu unserem Repertoire gehört auch die kreative Gestaltung mit der Sprühdose - von Leinwänden über Foodtrucks bis zu Häuserfassaden. Möglich sind Umsetzungen bezogen auf ihr Unternehmen, aber auch freie Gestaltungen und Graffiti sowohl für gewerbliche als auch für private Kunden.'
  }
]

const Services = () => {
  return (
    <div className='flex justify-center flex-wrap'>
      <p className='mt-24 mb-32 text-lg md:text-xl px-8 md:px-40 xl:px-52 text-white text-center leading-relaxed text-md font-light'>Wir beraten Sie gern über die grafischen, technischen sowie künstlerischen Umsetzungsmöglichkeiten und kreieren nach ihren Wünschen zielgruppengerechte Kommunikationslösungen oder ausdrucksstarke Kunstwerke. Ihre kreativen Wünsche werden bei uns Realität - mit Leidenschaft und Professionalität.</p>
      {SERVICES.map((item, index) => {
        return (
          <div className='sm:w-34 md:w-96 text-white flex items-center flex-col rounded-xl mb-8' key={index}>
            <div className='w-20 h-20'>{item.logo}</div>
            <div className='flex flex-col justify-center items-center p-4'>
              <div className='bg-primary h-1 mb-4' />
              <div className='text-white text-center font-bold text-2xl mb-4'>{item.title}</div>
              <div className='text-[#c1c1c1] text-center px-12'>{item.description}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Services
